import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { AppUser } from '@/models/app-user-dto'
import Commons from '@/components/shared/Helpers/commons'
import { dossierPatientModel } from '@/models/dossier-response-model'
import { Subscription } from 'rxjs'
import { PodoService } from '@/services/patient-podo-service'
import { PodiatryObservationModel } from '@/models/podiatry-observation-model'
import DocPreviewBase from '../DocPreviewBase.vue'

@Component({
  components: {
    DocPreviewBase
  }
})
export default class ObservationReport extends Vue {
  @Prop({ default: [] })
  public infirmieres!: AppUser[]

  @Prop({ default: [] })
  public reseauPro!: any[]

  private subscriptions: Subscription[] = []
  private podoService = PodoService.getInstance()
  private selectedDossier!: dossierPatientModel
  public patientFullName = ''
  public infirmiereId = ''
  public medecinId = ''
  public medecin: any = null
  public observation: PodiatryObservationModel | any = {}
  public content = ''

  public async mounted () {
    const cachedDossier = sessionStorage.getItem('selectedDossier')
    if (Commons.isCachedDossierPatientValid(cachedDossier)) {
      this.selectedDossier = JSON.parse(cachedDossier!)
      this.patientFullName = this.selectedDossier.fullName
    } else {
      this.$router.push({ name: 'patient' })
    }
    this.subscriptions.push(this.podoService.podoObservationSelected$.subscribe((observation: PodiatryObservationModel) => {
      this.observation = observation
      this.content = `Docteur,<br /><br />Observations pied gauche:<br />${observation.observationLeftFoot?.replaceAll('\n', '<br />') ?? ''}<br /><br />Observations pied droit:<br />${observation.observationRightFoot?.replaceAll('\n', '<br />') ?? ''}<br /><br />Observations pieds gauche et droit:<br />${observation.observationBothFeet?.replaceAll('\n', '<br />') ?? ''}`
    }))
  }

  get formattedInfirmiere () {
    const found = this.infirmieres.find(x => x.id === this.infirmiereId)
    if (found) {
      return `<strong>${Commons.newlines2br(found.fullName)}</strong><br /><em>${found.function ?? ''}</em>`
    }
    return ''
  }

  public doctorChanged (doctorId: string) {
    this.medecin = this.reseauPro.find(x => x.reseauMedicalExtra?.reseauId === doctorId)
    this.$emit('doctorChanged', this.medecin?.fullname)
  }
}
