import { Component, Vue } from 'vue-property-decorator'
import DocumentPreview from '@/components/shared/DocumentPreview/ObservationReport/ObservationReport.vue'
import Commons from '@/components/shared/Helpers/commons'
import { Subscription } from 'rxjs'
import { PodoService } from '@/services/patient-podo-service'
import { PodiatryObservationModel } from '@/models/podiatry-observation-model'
import { dossierPatientModel } from '@/models/dossier-response-model'
import { ValidationObserver } from 'vee-validate'
import ReportHelpers from '@/components/shared/Helpers/ReportHelpers'
import Alert from '@/components/shared/Alert.vue'
import { AuthService } from '@/services/auth-service'
import { AppUser } from '@/models/app-user-dto'
import { ErrorService } from '@/services/error.service'

@Component({
  components: {
    Alert,
    DocumentPreview,
    ValidationObserver
  }
})
export default class Report extends Vue {
  private subscriptions: Subscription[] = []
  private userService = AuthService.getInstance()
  private podoService = PodoService.getInstance()
  private selectedDossier!: dossierPatientModel
  public observation: PodiatryObservationModel | any = {}
  public patientFullName = ''
  public loading = false
  public saving = false
  public errorMessages: string[] = []
  public reseauPro: any[] = []
  public allInfirmieres: AppUser[] = []
  public doctorName = ''

  public async mounted () {
    this.loading = true
    this.saving = false
    Commons.updateDossierName()
    const cachedDossier = sessionStorage.getItem('selectedDossier')
    if (cachedDossier) {
      this.selectedDossier = JSON.parse(cachedDossier)
      this.patientFullName = this.selectedDossier.fullName
    }
    this.subscriptions.push(this.podoService.podoObservationSelected$.subscribe((observation: PodiatryObservationModel) => {
      this.observation = observation
    }))

    await this.getAllInfirmiereUsers()
    await Commons.getDossierReseau(this.selectedDossier.guid, this.errorMessages, this.reseauPro)
    this.loading = false
  }

  public async getDossierReseau () {
    if (this.selectedDossier.guid) {
      Commons.getDossierReseau(this.selectedDossier.guid, this.errorMessages, this.reseauPro)
    }
  }

  public async getAllInfirmiereUsers () {
    this.userService.getAllInfirmiereGroupUsers().then((infirmieres) => {
      this.allInfirmieres = infirmieres
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    })
  }

  public async savePDF () {
    this.saving = true
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      const documentPreview = this.$refs.documentpreview as any
      const model = ReportHelpers.GeneratePDFModel(3, `Rapport plaie-${this.patientFullName}-${new Date().toLocaleDateString()}.pdf`, documentPreview, '', this.doctorName, this.observation.appointmentId)
      if (this.selectedDossier.guid) {
        await ReportHelpers.UploadDocument(model, this.errorMessages, this.$router, this.selectedDossier.guid)
      }
    } else {
      const merged = Object.assign(this.$refs, (this.$refs.documentpreview as Vue).$refs)
      Commons.focusFirstComponentWithError(observer, merged, 'Ref')
    }
    this.saving = false
  }

  public goBack () {
    this.$router.push({ name: 'Podologique' })
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public doctorChanged (doctorName) {
    this.doctorName = doctorName
  }
}
